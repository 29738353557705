<template>
    <a-card>
        <a-page-header
            :title="`${id == 0 ? '新建创意组件模板' : '创意组件详情'}`"
        />

        <a-form-model
            ref="form"
            :model="form"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="组件适用范围" prop='componentScope'>
                <a-radio-group v-model="form.componentScope" v-if="id == 0 || pageType == 'copy'" @change="onChangeScope">
                    <a-radio :value="1">车系</a-radio>
                    <a-radio :value="2">品牌</a-radio>
                </a-radio-group>
                <span v-else>{{ form.componentScope == 1 ? '车系' : '品牌' }}</span>
            </a-form-model-item>
            <a-form-model-item label="品牌车系" prop="principalId" style="margin-bottom: 0">
                <a-select
                    v-model="form.principalId"
                    placeholder="请选择品牌"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    @change="onChangePrincipal"
                    v-if="id == 0 || pageType == 'copy'"
                >
                    <a-select-option
                        v-for="item in principalList"
                        :key="item.id"
                    >
                        {{ item.principal_name }}
                    </a-select-option>
                </a-select>
                <span v-else>{{ form.componentScope == 1 ? form.carSeriesName || '-' : form.principalName || '-' }}</span>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false" prop="carSeriesId" v-if="id == 0 || pageType == 'copy' && form.componentScope != 2">
                <a-select
                    v-model="form.carSeriesId"
                    placeholder="请选择车系"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    :disabled="!form.principalId"
                >
                    <a-select-option
                        v-for="item in seriesList"
                        :key="item.id"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="集客场景" prop='customerScene' style="margin-top: 24px;">
                <a-radio-group v-model="form.customerScene" v-if="id == 0 || pageType == 'copy'">
                    <a-radio
                        v-for="item in customerSceneList"
                        :key="item.code"
                        :value="item.code"
                    >
                        {{ item.desc }}
                    </a-radio>
                </a-radio-group>
                <span v-else>{{ form.customerSceneDesc || '-' }}</span>
            </a-form-model-item>
            <a-form-model-item label="卡片主图" ref="imageUrl" prop='imageUrl'>
                <base-upload
                    :upload-text="'上传照片'"
                    :upload-title="''"
                    :accept-list="'.jpg,.png,.webp'"
                    :has-file-list="uploadFileList"
                    :limit-size="1"
                    :params-data="uploadParamsData"
                    :is-limit-image-size="true"
                    :limit-image-size="{width: 108, height: 108}"
                    @handleUrl="handleUrl"
                    @handlePreview="handlePreviewOpen(arguments)"
                />
                <div class="txt"><span class="red">图片尺寸必须为108*108（否则上传或修改会失败）</span>，大小＜1M。支持JPG、PNG图片格式</div>
            </a-form-model-item>
            <a-form-model-item label="卡片标题" ref="title" prop='title'>
                <wordLimitInput 
                    :value="form.title"
                    :maxLength="20"
                    @onChangeInput="e => onChangeInput(e, 'title')"
                ></wordLimitInput>
            </a-form-model-item>
            <div v-for="(item, index) in form.productSellingPointArr" :key="index">
                <a-form-model-item
                    :label="'推广卖点' + (index + 1)"
                    ref="productSellingPointArr"
                    prop="productSellingPointArr"
                    v-if="index == 0"
                >
                    <div class="point-box">
                        <wordLimitInput 
                            :value="form.productSellingPointArr[index]"
                            :minLength="6"
                            :maxLength="9"
                            @onChangeInput="e => onChangeInput(e, 'productSellingPointArr', index)"
                        ></wordLimitInput>
                        
                        <a-icon type="plus-circle" class="icon plus" @click="onChangePoint('plus', index)" v-if="form.productSellingPointArr.length < 10" />
                    </div>
                    <div class="txt">最多支持10个推广卖点</div>
                    <div class="txt">程序化创意下，系统将展示最优的推广卖点。自定义创意下系统将取首个推广卖点进行展示。</div>
                </a-form-model-item>
                <a-form-model-item
                    :label="'推广卖点' + (index + 1)"
                    v-else
                >
                    <div class="point-box">
                        <wordLimitInput 
                            :value="form.productSellingPointArr[index]"
                            :minLength="6"
                            :maxLength="9"
                            @onChangeInput="e => onChangeInput(e, 'productSellingPointArr', index)"
                        ></wordLimitInput>
                        <a-icon type="minus-circle" class="icon minus" @click="onChangePoint('minus', index)" />
                    </div>
                </a-form-model-item>
            </div>
            <a-form-model-item label="行动号召" ref="buttonText" prop='buttonText'>
                <wordLimitInput 
                    :value="form.buttonText"
                    :minLength="2"
                    :maxLength="6"
                    @onChangeInput="e => onChangeInput(e, 'buttonText')"
                ></wordLimitInput>
            </a-form-model-item>
            <a-form-model-item label="组件名称" ref="componentName" prop='componentName'>
                <wordLimitInput 
                    :value="form.componentName"
                    :maxLength="20"
                    @onChangeInput="e => onChangeInput(e, 'componentName')"
                ></wordLimitInput>
            </a-form-model-item>
            <a-form-model-item label="智能优选">
                <a-checkbox v-model="form.enablePersonalAction">
                    智能优选
                </a-checkbox>
            </a-form-model-item>

            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>

        <preview-modal
            :visible="previewVisible"
            :preview-src="previewSrc"
            width="50%"
            @cancel="handlePreviewCancel"
        />
    </a-card>
</template>

<script>
import wordLimitInput from '@/components/wordLimitInput'
import BaseUpload from '@/components/BaseUpload/BaseUpload';
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";

export default {
    components: {
        wordLimitInput,
        BaseUpload,
        previewModal,
    },
    data() {
        let checkCarSeriesId = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择车系'))
            } else {
                callback();
            }
        }
        let checkProductSellingPointArr = (rule, value, callback) => {
            if (!this.form.productSellingPointArr[0]) {
                callback(new Error('请填写推广卖点'))
            } else {
                callback();
            }
        }
        
        return {
            id: this.$route.query.id,
            pageType: this.$route.query.type,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            form: {
                componentScope: 1,
                principalId: undefined,
                carSeriesId: undefined,
                customerScene: 1,
                imageUrl: '',
                title: '',
                productSellingPointArr: [''],
                buttonText: '',
                componentName: '',
                enablePersonalAction: false,
            },
            rules: {
                componentScope: [
                    { required: true, message: '请选择组件适用范围', trigger: 'change' }
                ],
                principalId: [
                    { required: true, message: '请选择品牌', trigger: 'change' }
                ],
                carSeriesId: [
                    { validator: checkCarSeriesId, trigger: 'change' }
                ],
                customerScene: [
                    { required: true, message: '请选择集客场景', trigger: 'change' }
                ],
                imageUrl: [
                    { required: true, message: '请选择卡片主图', trigger: 'change' }
                ],
                title: [
                    { required: true, message: '请填写卡片标题', trigger: 'change' }
                ],
                productSellingPointArr: [
                    { required: true, validator: checkProductSellingPointArr, trigger: 'change' }
                ],
                buttonText: [
                    { required: true, message: '请填写行动号召', trigger: 'change' }
                ],
                componentName: [
                    { required: true, message: '请填写组件名称', trigger: 'change' }
                ],
            },
            isSubmit: false,
            principalList: [],
            seriesList: [],
            tagList: [
                { id: 1, name: '地点' },
                { id: 2, name: '年龄' },
                { id: 3, name: '月份' },
                { id: 4, name: '节日' },
            ],
            customerSceneList: [],
            uploadFileList: [],
            uploadParamsData: {
                appId: "ad-oss",
                filePath: '/皮皮慧投/图片'
            },
            previewVisible: false,
            previewSrc: ''
        }
    },
    created() {
        this.getPrincipalList()
        this.getCustomerScene()

        if(this.id != 0) {
            this.getDetail()
        }
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangeScope(e) {
            this.form.carSeriesId = undefined
            if(e.target.value == 1) {
                this.getPrincipalSeriesList(this.form.principalId)
            }
        },
        onChangePrincipal(id) {
            this.form.carSeriesId = undefined
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: [id],
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        onChangeInput(val, type, index) {
            if((index ?? '') !== '') {
                this.$set(this.form[type], index, val)
                index == 0 ? this.$refs[type][index].onFieldChange() : ''
            } else {
                this.form[type] = val
                this.$refs[type].onFieldChange()
            }
        },
        onChangePoint(type, index) {
            if(type === 'plus') {
                this.form.productSellingPointArr.push('')
            } else {
                this.form.productSellingPointArr.splice(index, 1)
            }
        },
        getDetail() {
            this.$api.core.materialManage.getCreativeTemplateDetail(this.id).then(res => {
                if(res.code == 200) {
                    let _data = res.data
                    _data.enablePersonalAction = _data.enablePersonalAction == 1 ? true : false
                    this.uploadFileList = [{
                        uid: -1,
                        name: 'image/png',
                        status: 'done',
                        url: _data.imageUrl,
                        editor: true,
                    }]

                    Object.assign(this.form, _data)
                    if(_data.componentScope == 1) {
                        this.getPrincipalSeriesList(_data.principalId)
                    }
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/creativeTemplateManage'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    this.form.enablePersonalAction = this.form.enablePersonalAction ? 1 : 0
                    if(this.form.componentScope == 2) {
                        this.form.carSeriesId = undefined
                    }

                    let method = 'post'
                    if(this.pageType == 'copy') {
                        this.form.id = undefined
                    } else if(this.id != 0) {
                        method = 'put'
                    }
                    let body = {
                        data: this.form,
                        method
                    }
                    this.$api.core.materialManage.submitCreativeTemplateDetail( body ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/creativeTemplateManage'
                            })
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
        handleUrl(file) {
            this.form.imageUrl = file.xhr
        },
        handlePreviewOpen(data) {
            this.previewVisible = true
            this.previewSrc = data[0]
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.point-box {
    position: relative;
    .icon {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(50px, -50%);
        font-size: 22px;
        line-height: 0;
        cursor: pointer;
        &.plus {
            color: green;
        }
        &.minus {
            color: red;
        }
    }
}
.txt {
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1.5;
}
</style>